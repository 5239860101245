.item1 {
    grid-area: 2 / 1 / span 2 / span 3;
}
.gridcontainer {
    display: grid;
    grid-template-columns: 10rem 10rem 10rem;
    grid-gap: 10rem;
    background-color: #03070a;
    padding: 10px;
}

.gridcontainer {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}