.a4-sheet {

    width: 28cm;

    height: 40.7cm;

    margin: 0 auto;

    background-color: #ffffff;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    padding: 2cm;

    box-sizing: border-box;

}