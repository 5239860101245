.icon{
    display:none;
    
}
#icon:hover .icon{
    display: block;
    }
.icon{
    width:25px;
    height:25px;
    border-radius: 50%;
    color:blanchedalmond;
}